/* eslint-disable no-undef */
import _ from 'lodash';
import authentication from '../../auth/auth';

((window) => {
  // HACK: Injecting window object to put this helper in global scope.
  // TODO: Rewrite this helper into an injectable AngularJS service.
  const App = window.App || (window.App = {});

  const appLocalizationSource = abp.localization.getSource('Hms');
  App.localize = function () {
    return appLocalizationSource.apply(this, arguments);
  };

  App.downloadTempFile = (data) => {
    // window.open(data.fileToken, '_blank'); // This creates pop-up, browser might block.
    App.downloadFile(data.fileToken);
  };

  App.downloadFile = (url, fileName) => {
    // From fs-browsers npm
    const aElement = document.createElement('a');
    aElement.style.display = 'none';
    aElement.href = url;
    if (fileName) {
      aElement.download = fileName;
    }
    document.body.appendChild(aElement);
    aElement.click();
    document.body.removeChild(aElement);
  };

  App.isHost = () => abp.session.multiTenancySide === abp.multiTenancy.sides.HOST;

  App.isTenant = () => abp.session.multiTenancySide === abp.multiTenancy.sides.TENANT;

  App.isClinic = () => App.isTenant() && abp.features.isEnabled('HasClinicModule');

  App.isSpecialist = () =>
    App.isTenant() &&
    (abp.features.isEnabled('HasSpecialistModule') ||
      abp.features.isEnabled('HasOutpatientSpecialistService'));

  App.isPharmacy = () => App.isTenant() && abp.features.isEnabled('HasPharmacyModule');

  App.isCorporate = () => App.isTenant() && abp.features.isEnabled('HasCorporateModule');

  App.isHospital = () =>
    App.isTenant() &&
    abp.features.isEnabled('HasClinicModule') &&
    abp.features.getValue('ClinicModule.ClinicType') === '7';

  App.isInpatient = () =>
    App.isTenant() &&
    (abp.features.isEnabled('HasHospitalizationService') ||
      abp.features.isEnabled('HasMaternityService'));

  App.isHealthScreening = () =>
    App.isTenant() && abp.features.isEnabled('HasHealthScreeningService');

  App.createDateRangePickerOptions = () => {
    const today = moment().format('YYYY-MM-DD');
    const min = [2015, 0, 1];
    const options = {
      locale: {
        format: 'L',
        applyLabel: App.localize('Apply'),
        cancelLabel: App.localize('Cancel'),
        customRangeLabel: App.localize('CustomRange'),
      },
      min: moment(min),
      minDate: moment(min),
      max: moment(moment().endOf('day')),
      maxDate: moment(moment().endOf('day')),
      ranges: {},
    };

    options.ranges[App.localize('Last365Days')] = [
      moment().subtract(364, 'days').startOf('day'),
      moment().endOf('day'),
    ];
    options.ranges[App.localize('Today')] = [moment().startOf('day'), moment().endOf('day')];
    options.ranges[App.localize('Yesterday')] = [
      moment().subtract(1, 'days').startOf('day'),
      moment().subtract(1, 'days').endOf('day'),
    ];
    options.ranges[App.localize('ThisWeek')] = [moment().startOf('week'), moment().endOf('day')];
    options.ranges[App.localize('ThisMonth')] = [moment().startOf('month'), moment().endOf('day')];
    options.ranges[App.localize('ThisQuarter')] = [
      moment().startOf('quarter'),
      moment().endOf('day'),
    ];
    options.ranges[App.localize('ThisYear')] = [moment().startOf('year'), moment().endOf('day')];
    options.ranges[App.localize('LastWeek')] = [
      moment().subtract(1, 'week').startOf('week'),
      moment().subtract(1, 'week').endOf('week'),
    ];
    options.ranges[App.localize('LastMonth')] = [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ];
    options.ranges[App.localize('LastQuarter')] = [
      moment().subtract(1, 'quarter').startOf('quarter'),
      moment().subtract(1, 'quarter').endOf('quarter'),
    ];
    options.ranges[App.localize('LastYear')] = [
      moment().subtract(1, 'year').startOf('year'),
      moment().subtract(1, 'year').endOf('year'),
    ];
    options.ranges[App.localize('AllDates')] = [moment('2015-01-01'), moment().endOf('day')];
    return options;
  };

  App.createCustomDateRangePickerOptions = (minDate, maxDate) => {
    const options = {
      locale: {
        format: 'L',
        applyLabel: App.localize('Apply'),
        cancelLabel: App.localize('Cancel'),
        customRangeLabel: App.localize('CustomRange'),
      },
      ranges: {},
    };
    if (minDate) options.min = options.minDate = moment(minDate);
    if (maxDate) options.max = options.maxDate = moment(moment(maxDate).endOf('day'));
    return options;
  };

  App.createMapOptions = () => ({
    zoom: 17,
    styles: [
      {
        featureType: 'water',
        stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#808080' }, { lightness: 54 }],
      },
      {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [{ color: '#ece2d9' }],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [{ color: '#ccdca1' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#767676' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#ffffff' }],
      },
      { featureType: 'poi', stylers: [{ visibility: 'off' }] },
      {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [{ visibility: 'on' }, { color: '#b8cb93' }],
      },
      { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
      { featureType: 'poi.sports_complex', stylers: [{ visibility: 'on' }] },
      { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
      { featureType: 'poi.business', stylers: [{ visibility: 'simplified' }] },
    ],
  });

  App.evenRound = (num, decimalPlaces) => {
    const d = decimalPlaces || 0;
    const m = 10 ** d;
    const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
    const i = Math.floor(n);
    const f = n - i;
    const e = 1e-8; // Allow for rounding errors in f
    const r = f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
  };

  App.generateArrObjsParam = (array, property) => {
    let output = '';
    _.each(array, (arrValue, arrkey) => {
      _.forIn(arrValue, (value, key) => {
        output += `&${property}[${arrkey}].${key}=${value}`;
      });
    });
    return output;
  };

  App.convertObjToParam = (obj) => {
    let paramString = '';
    _.forIn(obj, (value, key) => {
      if (_.isNil(value)) delete obj[key];
      else if (_.isArrayLikeObject(value) && value.length > 0 && _.isPlainObject(value[0])) {
        paramString += App.generateArrObjsParam(value, key); // Generate parameter of the array of objects
        delete obj[key];
      }
    });
    return $.param(obj, true) + paramString;
  };

  App.formatUrl = (filePath, request) => abp.appPath + filePath + App.convertObjToParam(request);

  App.getStorageJsonObj = (storageKey) => {
    const item = localStorage.getItem(storageKey);
    return !_.isNil(item) ? JSON.parse(item) : null;
  };

  App.saveAsJsonToStorage = (storageKey, obj) => {
    if (_.isString(storageKey) && storageKey) {
      localStorage.setItem(storageKey, JSON.stringify(obj));
    }
  };

  App.swal = {
    disableButtons(durationInSec) {
      let counter = durationInSec; // in seconds.
      function countdown() {
        if (counter <= 0) {
          swal.enableButtons();
        } else {
          counter--;
          setTimeout(countdown, 1000);
        }
      }

      swal.disableButtons();
      if (durationInSec > 0) countdown();
    },
  };

  App.htmlEncode = (s) => $('<div>').text(s).html();

  App.htmlDecode = (s) => $('<div>').html(s).text();

  App.addBusinessDays = (moment, amount) => {
    function determineSign(x) {
      x = +x;
      return x > 0 ? 1 : -1;
    }

    if (amount === 0 || isNaN(amount)) {
      return moment;
    }

    const sign = determineSign(amount);
    const day = moment.day();
    const absIncrement = Math.abs(amount);

    let days = 0;

    if (day === 0 && sign === -1) {
      days = 1;
    } else if (day === 6 && sign === 1) {
      days = 1;
    }

    // Add padding for weekends.
    let paddedAbsIncrement = absIncrement;
    if (day !== 0 && day !== 6 && sign > 0) {
      paddedAbsIncrement += day;
    } else if (day !== 0 && day !== 6 && sign < 0) {
      paddedAbsIncrement += 6 - day;
    }
    const weekendsInbetween =
      Math.max(Math.floor(paddedAbsIncrement / 5) - 1, 0) +
      (paddedAbsIncrement > 5 && paddedAbsIncrement % 5 > 0 ? 1 : 0);

    // Add the increment and number of weekends.
    days += absIncrement + weekendsInbetween * 2;

    moment.add(sign * days, 'days');
    return moment;
  };

  App.touchFormErrors = (form) => {
    if (!form || !form.$error) return;

    // Touch fields with error in form to trigger highlight.

    _.forIn(form.$error, (e) => {
      _.forEach(e, (f) => {
        if (f.$setTouched) f.$setTouched();
        else App.touchFormErrors(f); // To support nested forms. FormController doesn't have $setTouched.
      });
    });
  };

  App.getEmailRegex = () =>
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  App.isFormValid = (form) => {
    App.touchFormErrors(form);
    return form.$valid;
  };

  // Method is only used to round monetary values to 2 decimal places.
  App.roundAmount = (amount) => Math.round(amount * 100) / 100;

  App.setPagination = (requestParams, callback) => {
    let timeoutCall;
    return (newPage, pageSize) => {
      requestParams.skipCount = (newPage - 1) * pageSize;
      requestParams.maxResultCount = pageSize;
      if (timeoutCall) {
        clearTimeout(timeoutCall);
      }
      timeoutCall =
        callback &&
        setTimeout(() => {
          callback();
        }, 400);
    };
  };

  App.getLocalDate = (date) => {
    // moment follow the GMT offset changes in Malaysia history,
    // causes offset changes when parsing (e.g. 1982- 01 - 01 UTC offset change from 7:30 to 8:00).
    // To avoid that, we take only the date and convert to local time.

    return moment(date, 'YYYY-MM-DD').local();
  };

  App.getUtcDate = (date) => {
    // Returns the date value of the UTC date.

    return moment.utc(date, 'YYYY-MM-DD').startOf('day');
  };

  App.getDateStringFormat = () => 'D/M/YYYY';
  App.getDateTimeStringFormat = () => {
    if (abp.setting.getBoolean('Hms.Feature.IndonesiaAndThailandRegionalExpansion'))
      return 'D/M/YYYY h:mm A Z';

    return 'D/M/YYYY h:mm A';
  };
  App.getFullDateTimeStringFormat = () => 'LLLL Z';
  App.getTimeStringFormat = () => 'h:mm A Z';

  App.getDateString = (datetime) => moment(datetime).format(App.getDateStringFormat());

  // Check is date is in YYYY-MM-DD format.
  App.isDate = (datetime) => {
    return moment(datetime, moment.ISO_8601, true).isValid();
  };

  App.getDateTimeString = (datetime, timezone) => {
    // Returns the date time value of the specific time zone.
    if (!timezone) return moment(datetime).format(App.getDateTimeStringFormat());

    return moment(datetime).tz(timezone).format(App.getDateTimeStringFormat());
  };

  App.getFullDateTimeString = (dateTime, timeZone) => 
    moment(dateTime).tz(timeZone).format(App.getFullDateTimeStringFormat());

  App.setDateTimeTimeZone = (dateTime, timeZone) =>
    // Returns the date time value of the specific time zone as moment object.

    moment(dateTime).tz(timeZone);

  App.getAppProductName = () => ['Flutter', 'Xamarin'];

  App.getWebProductName = () => ['IonicPwa', 'FlutterPwa'];

  App.getAge = (dob, currentDate) => {
    if (!moment.isMoment(currentDate)) {
      currentDate = moment(currentDate);
    }

    if (!currentDate.isValid()) {
      currentDate = moment();
    }

    if (!moment.isMoment(dob)) {
      dob = moment(dob);
    }

    if (!dob.isValid()) {
      return null;
    }

    return moment.duration(currentDate.diff(dob));
  };

  App.normalizeText = (text) => {
    if (!text) return '';
    return text.replace(/[^\w\d]/g, '').toUpperCase();
  };

  App.dateRangeNgPattern = new RegExp(
    '^[0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4} - [0-9]{1,2}[/][0-9]{1,2}[/][0-9]{4}$'
  );

  App.getDaysDifference = (firstDate, secondDate) => {
    firstDate = moment(firstDate, 'YYYY-MM-DD');
    secondDate = moment(secondDate, 'YYYY-MM-DD');

    // secondDate > firstDate
    return secondDate.diff(firstDate, 'days');
  };

  // Retrieve access token. Fallback when the authentication server failed to return a user.
  App.getAccessToken = () => authentication.accessToken();

  App.getFileUploaderHeaders = () => ({
    'X-XSRF-TOKEN': abp.security.antiForgery.getToken(),
    Authorization: `Bearer ${App.getAccessToken()}`,
  });

  // Indicate if this is a GP panel.
  App.isGPPanel = () =>
    App.isTenant() &&
    abp.features.isEnabled('HasClinicModule') &&
    abp.features.getValue('ClinicModule.ClinicType') === '1';
})(window);
