/* global __IDENTITY_SERVER_ENDPOINT__, __IDENTITY_SERVER_CLIENT_ID__ */
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import * as jose from 'jose'; // JWT token validator.
import moment from 'moment';
import swal from 'sweetalert';

const storage = window.localStorage;
const userStore = new WebStorageStateStore({ store: storage });
const tokenExpiringNotificationTime = 300;
const clockSkewInSeconds = 300; // 5 minutess

const userManagerSetting = {
  authority: __IDENTITY_SERVER_ENDPOINT__,
  client_id: __IDENTITY_SERVER_CLIENT_ID__,
  redirect_uri: `${window.location.origin}/login_callback.html`,
  post_logout_redirect_uri: window.location.origin,
  scope: __IDENTITY_SERVER_SCOPE__,
  silent_redirect_uri: `${window.location.origin}/login_callback.html`,
  response_type: 'code',
  loadUserInfo: true,
  redirectMethod: 'replace',
  automaticSilentRenew: false,
  // Set the notification time to 300 seconds
  accessTokenExpiringNotificationTimeInSeconds: tokenExpiringNotificationTime,
  userStore,
  validateSubOnSilentRenew: true,
  revokeAccessTokenOnSignout: true,
};

const userManager = new UserManager(userManagerSetting);

function getUser() {
  // Get user from the user manager.
  // If there is no user found, attempt to log the user in.
  // This will always ensure that the user is logged in.

  return new Promise((resolve, reject) => {
    userManager
      .getUser()
      .then((user) => {
        const currentUnixTime = moment().unix();

        // Check if user is logged in. If not, redirect user to login.
        if (_.isNil(user)) {
          login();
          resolve(null);
        }

        // Validate the access token with the not valid before time.
        const userClaim = user ? jose.decodeJwt(user.access_token) : null;

        // If the user claim that was issued is future-dated, re-login.
        // nbf (not before) = indicate the effective start time of the token.
        if (currentUnixTime < userClaim.nbf - clockSkewInSeconds * 1000) {
          swal(
            {
              title: "You're not sign in.",
              text: `Your computer thinks it's ${App.getDateTimeString(
                moment()
              )}, which prevents us from signing you in. To continue, update your computer clock in your system settings to the current date, time, and time zone, and click the Refresh button below.`,
              confirmButtonText: 'Refresh',
            },
            () => {
              window.location.reload();
            }
          );
        }

        // exp (expiration time) indicates the effective end time of the token.
        if (currentUnixTime > userClaim.exp) {
          refreshAccessToken().then((refreshedUser) => {
            resolve(refreshedUser);
          });
        } else {
          resolve(user);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        return reject(err);
      });
  });
}

function login() {
  return userManager.signinRedirect({ state: window.location.href });
}

function refreshAccessToken() {
  return new Promise((resolve) => {
    // Request for new access token if it has not been requested
    // or after 5 minutes of the last request.

    const refreshTokenInProgress = localStorage.getItem('refreshTokenRequestInProgress');
    if (
      !_.isNil(refreshTokenInProgress) &&
      moment(refreshTokenInProgress).add(300, 'seconds').unix() > moment().unix()
    ) {
      return;
    }

    const accessToken = getAccessToken();
    const userClaim = accessToken ? jose.decodeJwt(accessToken) : null;

    // Return user if the access token is expiring or expired.

    if (
      userClaim &&
      moment().add(tokenExpiringNotificationTime, 'seconds').unix() <= userClaim.exp
    ) {
      // Trigger addUserLoaded event for other tabs if another tab has already renewed the token.
      // So, the tab will update the access token for Ajax.
      // Refer to addUserLoaded event in hms-ng-auth.module.js.
      resolve(getUser());
    }

    localStorage.setItem('refreshTokenRequestInProgress', moment());

    userManager
      .signinSilent()
      .then((user) => {
        resolve(user);
      })
      .catch((error) => {
        console.error(error);
        logout();
      })
      .finally(() => {
        localStorage.removeItem('refreshTokenRequestInProgress');
      });
  });
}

function logout() {
  const user = getUserToken();
  const currentTenantId = JSON.parse(user).profile.tenantid;
  return userManager.signoutRedirect({
    extraQueryParams: {
      tenantId: currentTenantId,
    },
  });
}

function impersonate(userId, tenantId) {
  if (!userId || !tenantId) {
    abp.message.error('Error impersonating user. Error code: IM01');
    return null;
  }

  if (isImpersonatedLogin()) {
    abp.message.error(
      "You can't impersonate while you are in an impersonated account. Error code: IM02"
    );
    return null;
  }

  const user = getUserToken();
  const currentTenantId = JSON.parse(user).profile.tenantid;

  if (!currentTenantId) {
    abp.message.error('Error impersonating user. Error code: IM03');
    return null;
  }

  return userManager.signoutRedirect({
    extraQueryParams: {
      isImpersonating: true,
      impersonationUserId: userId,
      impersonationTenantId: tenantId,
      tenantId: currentTenantId,
    },
  });
}

function unimpersonate() {
  const user = getUserToken();
  const currentTenantId = JSON.parse(user).profile.tenantid;
  if (isImpersonatedLogin()) {
    return userManager.signoutRedirect({
      extraQueryParams: {
        isBackToImpersonator: true,
        impersonatorUserId: JSON.parse(user).profile.impersonatorUserId,
        impersonatorTenantId: JSON.parse(user).profile.impersonatorTenantId,
        tenantId: currentTenantId,
      },
    });
  }

  abp.message.error('User is not impersonated');
  return null;
}

function isImpersonatedLogin() {
  const user = getUserToken();
  return !!(
    user &&
    JSON.parse(user).profile.impersonatorUserId &&
    JSON.parse(user).profile.impersonatorTenantId
  );
}

function getUserToken() {
  return storage.getItem(
    `oidc.user:${__IDENTITY_SERVER_ENDPOINT__}:${__IDENTITY_SERVER_CLIENT_ID__}`
  );
}

function getAccessToken() {
  const user = getUserToken();
  const userIdentity = JSON.parse(user);

  // Only return access token if it isn't expired.

  if (userIdentity && moment().unix() <= userIdentity.expires_at) return userIdentity.access_token;

  return null;
}

export default {
  userManager,
  getUser,
  login,
  logout,
  refreshAccessToken,
  accessToken: getAccessToken,
  impersonate,
  unimpersonate,
  isImpersonatedLogin,
};
